<template>
  <el-main class="kanban-board">
    <draggable
      v-if="kanban.kanbanColumns"
      v-model="kanban.kanbanColumns.items"
      group="kanbanColumns"
      ghost-class="ghost-card"
      style="display: flex; column-gap: 10px"
      @end="dragEnd"
    >
      <el-card
        v-for="column in kanban.kanbanColumns"
        :key="column.uuid"
        class="card-list"
      >
        <kanban-column-header
          :column="column"
          @delete-column="deleteColumn($event)"
        />

        <div class="card-list-body">
          <draggable
            v-model="column.tasks.items"
            :animation="200"
            ghost-class="ghost-card"
            group="tasks"
            @change="changed(column, $event)"
          >
            <div
              v-for="(task) in column.tasks"
              :key="task.uuid"
              class="task-card-container"
            >
              <kanban-task-card
                :task="task"
                :column-title="column.title"
                class="cursor-move"
                @add-task="addTask(column, task.uuid, $event)"
                @archive-task="archiveTask(column, task)"
                @delete-task="deleteTask(column, task)"
                @show-modal="showModalWindow($event, column)"
              />
            </div>
          </draggable>
        </div>

        <new-task-input
          :show-default-btn="true"
          :show-input="false"
          @add-task="addTask(column, null, $event)"
        />
      </el-card>
    </draggable>

    <el-card
      class="card-list add-new-list"
      :style="showArchive ? { 'margin-right': '300px' } : ''"
    >
      <div
        class="kanban-column"
        :class="{'active-input': showAddColumnInput}"
      >
        <el-input
          v-if="showAddColumnInput"
          v-model="newColumnTitle"
          size="mini"
          placeholder="List title"
          class="column-title"
          @blur="closeInputNewColumn"
          @keyup.enter.native="addNewColumn"
        />
        <el-button
          size="mini"
          type="primary"
          class="new-column-btn"
          @click.prevent.native="addNewColumn"
        >
          Add list
        </el-button>
      </div>
    </el-card>

    <!--    Archive-->
    <transition name="archive">
      <el-card
        v-if="showArchive"
        class="card-list archived-tasks"
      >
        <p class="archived-title">
          Archived tasks
        </p>
        <div
          v-if="archive.tasks.items.length"
          class="card-list-body"
          :style="{ 'padding-bottom': '10px' }"
        >
          <div
            v-for="(task) in archive.tasks"
            :key="task.uuid"
            class="task-card-container"
          >
            <kanban-task-card
              :task="task"
              :is-archive="true"
              class="cursor-move"
              @delete-task="deleteTask(archive, task)"
              @show-modal="showModalWindow($event, archive)"
            />
          </div>
        </div>
        <p
          v-else
          class="archived-title no-tasks"
        >
          No tasks
        </p>
      </el-card>
    </transition>

    <modal-column
      v-show="showModal"
      :task="modalTask"
      :column="modalColumn"
      @close-modal-task="closeModal"
    />
  </el-main>
</template>

<script>
import draggable from 'vuedraggable';
import {
  KanbanColumn,
  KanbanColumnRepository,
  Task,
} from '@timeragent/core';

export default {
  name: 'KanbanBoard',
  components: {
    KanbanTaskCard: () => import('./KanbanTaskCard.vue'),
    KanbanColumnHeader: () => import('./KanbanColumnHeader.vue'),
    NewTaskInput: () => import('./NewTaskInput.vue'),
    ModalColumn: () => import('./TaskModalWindow.vue'),
    draggable,
  },
  props: {
    kanban: {
      type: Object,
      required: true,
    },
    fetchColumnsAction: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    showArchive: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      showAddColumnInput: false,
      isDragging: false,
      newColumnTitle: '',
      showModal: false,
      modalTask: null,
      modalColumn: {},
      archive: null,
      kanbanColumnRepository: new KanbanColumnRepository(),
    };
  },
  watch: {
    fetchColumnsAction(value) {
      if (value) {
        this.fetchColumns();
      }
    },
  },
  async created() {
    await this.fetchColumns();

    // Get column/task uuids from $router
    const { column, task } = this.$route.query;
    const localColumn = this.kanban.kanbanColumns.items.find(c => (c.uuid === column ? c : null));

    if (task) {
      this.openedTask = localColumn.tasks.items.find(t => (t.uuid === task ? t : null));
      this.showModalWindow(this.openedTask, localColumn, true);
    }
  },
  methods: {
    async fetchColumns() {
      await this.kanbanColumnRepository.many({
        kanbanUuid: this.kanban.uuid,
      });

      this.archive = this.kanbanColumnRepository.dataset.filter(c => c.isArchive === true);
      this.kanban.kanbanColumns = this.kanbanColumnRepository.dataset.filter(c => c.isArchive === false);

      if (this.archive.tasks) {
        this.archive.tasks = this.archive.tasks.sort((a, b) => new Date(b.archivedAt) - new Date(a.archivedAt));
      }
    },
    addTask(col, taskId = undefined, title) {
      const newTask = new Task({
        title,
        kanbanColumnUuid: col.uuid,
        position: col.tasks.length + 1 || col.tasks.items.length + 1,
      });

      newTask.create();
      col.tasks.push(newTask);
    },
    async archiveTask({ tasks }, task) {
      task.kanbanColumnUuid = this.archive.uuid;
      task.archivedAt = new Date();
      await task.save();

      tasks.items = tasks.items.filter(t => t.uuid !== task.uuid);
      this.archive.tasks.items.unshift(task);
    },
    async deleteTask({ tasks }, task) {
      tasks.items = tasks.items.filter(t => t.uuid !== task.uuid);
      await task.delete();
    },
    async addNewColumn() {
      if (!this.showAddColumnInput) this.showAddColumnInput = true;
      if (!this.newColumnTitle) return;

      const localColumn = await new KanbanColumn({
        title: this.newColumnTitle,
        kanbanUuid: this.kanban.uuid,
        position: this.kanban.kanbanColumns.length + 1 || this.kanban.kanbanColumns.items.length + 1,
      });

      await localColumn.save();
      this.kanban.kanbanColumns.push(localColumn);
      this.newColumnTitle = '';
    },
    closeInputNewColumn() {
      setTimeout(() => {
        this.newColumnTitle = '';
        this.showAddColumnInput = false;
      }, 100);
    },
    async deleteColumn(column) {
      if (column.tasks.items.length) {
        column.tasks = column.tasks.map(item => {
          item.kanbanColumnUuid = this.archive.uuid;
          item.archivedAt = new Date();

          return item;
        });

        this.archive.tasks.items.unshift(...column.tasks.items);
        await column.updateTasks();
      }

      await column.delete();
      this.kanban.kanbanColumns = this.kanban.kanbanColumns.filter(c => c.uuid !== column.uuid);
    },
    async dragEnd() {
      const localColumns = this.sortingPositions(this.kanban.kanbanColumns);

      await this.kanban.updateColumns(localColumns);
    },
    async changed(column, { added, removed, moved }) {
      if (added) {
        added.element.kanbanColumnUuid = column.uuid;
        await added.element.save();

        column.tasks = this.sortingPositions(column.tasks);
        await column.updateTasks();
      }

      if (removed || moved) {
        column.tasks = this.sortingPositions(column.tasks);
        await column.updateTasks();
      }
    },
    sortingPositions(arr) {
      return arr.map((item, index) => {
        item.position = index + 1;

        return item;
      });
    },
    showModalWindow(task, col, created = false) {
      this.showModal = true;
      this.modalTask = task;
      this.modalColumn = col;

      if (!created && col.isArchive === false) {
        this.$router.push({
          name: 'kanban',
          query: { project: this.$route.query.project,
            kanban: this.$route.query.kanban,
            column: col.uuid,
            task: task.uuid },
        });
      }
    },
    closeModal() {
      this.showModal = false;

      if (!this.modalColumn.isArchive) {
        this.$router.push({
          name: 'kanban',
          query: { project: this.$route.query.project,
            kanban: this.$route.query.kanban },
        });
      }
    },
  },
};
</script>

<style
  lang="stylus"
  rel="stylesheet/css"
  scoped
>
.kanban-board
  display flex
  overflow-x scroll
  width 100%
  margin-right auto
  margin-left auto

.card-list {
  flex: 1 0 auto;
  width: 258px;
  max-width: 260px;
  overflow: auto;
  height: min-content;
  max-height: calc(100vh - 210px);
  display: flex;
  padding: 10px 10px 0 10px;
  background-color: #f7f7f7;
  box-shadow: unset;
  flex-direction: column;
}
::v-deep {
  .card-list > .el-card__body {
    display: grid;
    grid-template-rows: 30px 1fr min-content;
    max-height: calc(100vh - 210px)
    padding: 0;
  }
  .archived-tasks > .el-card__body {
    display: grid;
    grid-template-rows: 30px 1fr;
    max-height: calc(100vh - 230px)
  }
  .task-card-wrap > .el-card__body {
    display: block;
  }
}
.archived-title {
  padding: 3px 7px;
  font-size: 16px;
  line-height: 1.2;
}
.no-tasks {
  text-align: center;
  padding: 25px;
  color: #a0a0a0;
}
.archived-tasks {
  padding: 13px 25px;
  position: fixed;
  color: #525252;
  right: 0;
  background-color: #f4f4f4;
}
.archive-enter-active {
  transition: all 0.7s ease;
}
.archive-leave-active {
  transition: all 0.5s ease;
}
.archive-enter,
.archive-leave-to {
  transform: translateX(350px);
}
.add-new-list {
  height: 37px;
  margin-left: 10px;
}
.btn-options {
  border: none;
  color: gray;
  background: #ffffff;
  cursor: pointer;
}
.btn-options:hover {
  opacity: 0.8;
}
.card-list-body {
  height: 100%;
  overflow-y: scroll;
  min-height: 1rem;
}
.task-card-container {
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}
.cursor-move {
  cursor: pointer;
}
.blue {
  color: #4299e1;
}
button p {
  margin: 0;
  font-size: 16px;
}
.add-list-btn {
  width: 290px;
  font-size: 20px;
}
.new-column input {
  height: min-content;
}
.column-title {
  border: none;
  padding: 3px 7px;
  background-color: #f7f7f7;
  width: 100%;
}
.active-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
}
.ghost-card {
  opacity: 0.5;
  cursor: move;
}
.ghost-card:focus {
  cursor: pointer;
}
.card-detail-title {
  width: 93%;
  border: none;
  color: #4a4a4a;
  font-size: 14px;
  resize: none;
  padding: 4px 10px;
}
.container-add-task {
  padding: 10px 0;
}
.container-add-task button {
  margin: 4px 10px 0 0;
  background-color: #4299e1;
  color: #ffff;
  padding: 1px 7px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}
.btn-plus {
  font-size: 16px;
  cursor: pointer;
}
</style>
